import React, { useContext } from "react";

export type VariablesContextType = {
    phone?: string;
    email?: string;
};

export const VariablesContext = React.createContext<VariablesContextType | null>(null);

export const useVariablesContext = (): VariablesContextType => {
    const variablesContext = useContext(VariablesContext);
    if (variablesContext === null) {
        throw new Error("No Variables Context set!");
    }

    return variablesContext;
};
