import { Step } from "../components/configurator/steps/Step";
import { create } from "zustand";

type StepState = {
    step: Step | null;
    setStep: (step: Step) => void;
};

export const useStep = create<StepState>()((set) => ({
    step: null,
    setStep: (step) => set(() => ({ step: step })),
}));
