import { create } from "zustand";

type Cover = {
    className: string;
    price: number;
    catalog?: { name: string; id: string; shininess: number; repeat?: number } | undefined;
    color?: { url: string; id: string; name: string } | undefined;
};

type Frame = {
    id: string;
    name: string;
    price: number;
    texture?:
        | {
              name: string;
              shininess: number;
              cost: number;
              id: string;
          }
        | undefined;
};

type SecondaryFrame = {
    shininess: number;
    id: string;
};

type Extra = {
    articleID: string;
    name: string;
    price: number;
};

export type ChairModel = {
    id: string;
    modelName?: string | undefined;
    modelId?: string | undefined;
    variant?: string | undefined;
    variantDescription?: string | undefined;
    cover?: Cover | undefined;
    frame?: Frame | undefined;
    secondaryFrame?: SecondaryFrame | undefined;
    extra?: Extra | undefined;
};

type ChairModelState = {
    chair: ChairModel;
    setChair: (chair: ChairModel) => void;
    reset: (chair: ChairModel) => void;
};

export const useChair = create<ChairModelState>()((set) => ({
    chair: { id: "" },
    setChair: (chair) => set((state) => ({ chair: { ...state.chair, ...chair } })),
    reset: (chair) =>
        set(() => {
            return { chair: chair };
        }),
}));
