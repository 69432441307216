import { ChairModel } from "../context/ChairContext";

export const toLocalNumber = (value: number): string => {
    return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const calculatePrice = (chair: ChairModel) => {
    return chair.frame.price + chair.frame.texture.cost + chair.cover.price + chair.extra.price;
};

export const mod = (n: number, m: number) => {
    return ((n % m) + m) % m;
};

export const plausibleEvent = (event: string, props: string | undefined = undefined) => {
    // @ts-ignore
    window.plausible =
        // @ts-ignore
        window.plausible ||
        function () {
            // @ts-ignore
            if (!window.plausible) window.plausible = { q: [] };
            // @ts-ignore
            (window.plausible.q = window.plausible.q || []).push(arguments);
        };

    // @ts-ignore
    const plausible = window.plausible;

    if (props !== undefined) {
        plausible(event, { props: { name: props } });
    } else {
        plausible(event);
    }
};
