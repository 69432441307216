import React from "react";
import { ChairOverview } from "../../api/hooks/useChairs";
import { useStep } from "../../context/stepContext";
import { useChair } from "../../context/ChairContext";
import { Step } from "../configurator/steps/Step";
import { plausibleEvent } from "../../utilities/helper";

type ChairPreviewProps = {
    chairs: Array<ChairOverview>;
    classes: string;
    configuratorUrl: string;
};

export const ChairPreview = (props: ChairPreviewProps) => {
    const { step, setStep } = useStep();
    const { setChair } = useChair();

    const handleConfigureClick = (id: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set("id", id);
        window.history.pushState({ id: id }, "", url);

        setChair({
            id: id,
        });
        setStep(Step.OVERVIEW);
    };

    return props.chairs.length > 0 ? (
        <ul className={`chair-list ${props.classes}`}>
            {props.chairs.map((chair) => {
                return (
                    <li className={"chair-entry"} key={chair.id}>
                        {chair.previewImage.length > 0 ? (
                            <img
                                className={"chair-preview-image"}
                                src={chair.previewImage[0].url}
                                width={200}
                                height={200}
                                alt={chair.previewImage[0].title}
                            />
                        ) : (
                            <img
                                className={"chair-preview-image"}
                                src={"https://placehold.co/200/white/black?text=Kein+Foto+Verf%C3%BCgbar"}
                                width={200}
                                height={200}
                                alt={"placeholder"}
                            />
                        )}
                        <div className={"chair-description"}>
                            <div className={"chair-name"}>{chair.parent.chairName}</div>
                            <div className={"chair-variant"}>{chair.variantName}</div>
                        </div>
                        {step !== null ? (
                            <button
                                className={"button primary"}
                                onClick={() => {
                                    plausibleEvent(
                                        "variant-selected",
                                        `${chair.parent.chairName} ${chair.variantName}`
                                    );
                                    handleConfigureClick(chair.id);
                                }}
                            >
                                Konfigurieren
                            </button>
                        ) : (
                            <a
                                href={`${props.configuratorUrl}?id=${chair.id}`}
                                className={"button primary"}
                                onClick={() => {
                                    plausibleEvent(
                                        "variant-selected",
                                        `${chair.parent.chairName} ${chair.variantName}`
                                    );
                                }}
                            >
                                Konfigurieren
                            </a>
                        )}
                    </li>
                );
            })}
        </ul>
    ) : (
        <h4>Kein Ergebnis zu den gewählten Kriterien gefunden</h4>
    );
};
