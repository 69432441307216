import { create } from "zustand";

type CatalogFilter = {
    search?: string;
    filter?: Array<string>;
    page?: number;
};

type CatalogFilterType = {
    filter: CatalogFilter;
    setFilter: (filter: CatalogFilter) => void;
};

export const useCatalogFilter = create<CatalogFilterType>()((set) => ({
    filter: { search: "", filter: ["chair", "barstool", "bowlchair", "armrest"], page: 0 },
    setFilter: (filter) => set((state) => ({ filter: { ...state.filter, ...filter } })),
}));
