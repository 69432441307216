import React, { useEffect, useState } from "react";
import { SearchAndFilter } from "./SearchAndFilter";
import { ChairOverview, useListChairsCatalog } from "../../api/hooks/useChairs";
import { ChairPreview } from "./ChairPreview";
import { Loader } from "../Loader";
import { ContextWrapper } from "../ContextWrapper";
import { useCatalogFilter } from "../../context/CatalogContext";

type CatalogProps = {
    heading: string;
    token: string;
    url: string;
    csrf: string;
    configuratorUrl: string;
};

type CatalogContentProps = {
    heading: string;
    configuratorUrl: string;
};

export const Catalog = (props: CatalogContentProps) => {
    const { filter, setFilter } = useCatalogFilter();
    const [entries, setEntries] = useState<Array<ChairOverview>>([]);
    const [currentLength, setCurrentLength] = useState<number>(0);
    const limit = 10;

    const chairCatalogQuery = useListChairsCatalog(
        {
            categories: filter.filter,
            search: filter.search,
        },
        filter.page,
        limit
    );

    useEffect(() => {
        if (chairCatalogQuery.isFetched) {
            setCurrentLength(chairCatalogQuery.data.chairEntries.length);
            if (filter.page === 0) {
                setEntries(chairCatalogQuery.data.chairEntries);
            } else {
                const newEntries = entries.concat(chairCatalogQuery.data.chairEntries);
                setEntries(newEntries);
            }
        }
    }, [chairCatalogQuery.isFetched, chairCatalogQuery.isRefetching]);

    return (
        <div className={"catalog-content"}>
            {chairCatalogQuery.isFetching ? <Loader className={"loading"} /> : null}
            <div className={"top-bar"}>
                <h3 className={"title"}>{props.heading}</h3>
                <SearchAndFilter />
            </div>
            {chairCatalogQuery.isSuccess ? (
                <ChairPreview
                    chairs={entries}
                    classes={chairCatalogQuery.isFetching ? "is-fetching" : ""}
                    configuratorUrl={props.configuratorUrl}
                />
            ) : (
                <div className={"empty-catalog"}></div>
            )}
            {currentLength >= limit ? (
                <button
                    className={`button secondary load-more ${
                        chairCatalogQuery.isFetching ? "icon-button fetching" : ""
                    }`}
                    onClick={() => {
                        setFilter({ page: filter.page + 1 });
                    }}
                >
                    Mehr Produkte Laden
                </button>
            ) : null}
        </div>
    );
};

export const CatalogWrapper = (props: CatalogProps) => {
    return (
        <ContextWrapper token={props.token} url={props.url} csrf={props.csrf}>
            <Catalog heading={props.heading} configuratorUrl={props.configuratorUrl} />
        </ContextWrapper>
    );
};
