import { GraphQLContext, GraphQLContextType } from "../context/graphQLContext";
import { QueryContext, QueryContextType } from "../context/queryContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { PropsWithChildren } from "react";
import { VariablesContext, VariablesContextType } from "../context/VariablesContext";

export type ContextWrapperProps = PropsWithChildren & {
    url: string;
    token: string;
    csrf: string;
    phone?: string;
    email?: string;
};

export const ContextWrapper = (props: ContextWrapperProps) => {
    const queryClient = new QueryClient();

    const graphqlOptions: GraphQLContextType = {
        url: props.url,
        requestHeaders: {
            Authorization: "Bearer " + props.token,
        },
    };

    const queryContext: QueryContextType = {
        csrf: props.csrf,
    };

    const variablesContext: VariablesContextType = {
        phone: props.phone,
        email: props.email,
    };

    return (
        <VariablesContext.Provider value={variablesContext}>
            <QueryClientProvider client={queryClient}>
                <GraphQLContext.Provider value={graphqlOptions}>
                    <QueryContext.Provider value={queryContext}>{props.children}</QueryContext.Provider>
                </GraphQLContext.Provider>
            </QueryClientProvider>
        </VariablesContext.Provider>
    );
};
