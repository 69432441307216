import request from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { useGraphQLContext } from "../../context/graphQLContext";
import {
    getChairConfiguratorInfo,
    getChairVariantInfo,
    getChairVariantUrl,
    listChairsCatalog,
    listChairVariants,
    listVariantCategoryFinishing,
    listVariantCovers,
} from "../../queries/chairs";

export type ChairOverview = {
    id: string;
    itemNumber: string;
    variantName: string;
    parent: {
        chairName: string;
    };
    previewImage: [
        {
            url: string;
            title: string;
        }
    ];
};

export type ChairVariantInfo = {
    variantName: string;
    parent: { chairName: string; notes: Array<{ note: string }> };
    itemNumber: string;
    measurements: string;
    fabricLFM: number;
    leatherM2: number;
    variantCost: Array<{ coverCost: number }>;
};

export type ChairConfiguratorInfo = {
    variantName: string;
    parent: {
        id: string;
        chairName: string;
        accessory: Array<{ accessoryCost: number; accessoryName: Array<{ articleID: string; title: string }> }>;
    };
    variantDescription: string | null;
    frames: Array<{
        frame: Array<{
            id: string;
            frameName: string;
            textures: Array<{ cost: number; shininess: number; textureName: string; texture: Array<{ id: string }> }>;
        }>;
        frameCost: number;
    }>;
    secondaryFrame: Array<{ shininess: number; secondaryFrameTexture: Array<{ id: string }> }>;
};

export type ChairVariants = {
    id: string;
    chairName: string;
    children: Array<{
        id: string;
        variantName: string;
        variantDescription: string;
        previewImage: Array<{ url: string; title: string }>;
    }>;
};

export type ChairOverviewVariables = {
    categories: Array<string>;
    search: string;
};

export type ChairModelUrlOut = {
    chairEntries: Array<{ variantName: string; variantModel: Array<{ url: string }> }>;
};

export type VariantCost = {
    cover: Array<{ id: string; category: string; title: string }>;
    coverCost: number;
};

export type VariantCovers = {
    variantCost: Array<VariantCost>;
};

export type VariantFinishing = {
    variantCost: Array<{
        cover: Array<{
            id: string;
            title: string;
            category: string;
            collection: Array<{
                id: string;
                colors: Array<{ id: string }>;
                finishing: Array<{
                    id: string;
                    title: string;
                }>;
            }>;
        }>;
    }>;
};

export type ChairEntries<T> = {
    chairEntries: Array<T>;
};

export type Finishings = {
    finishingEntries: Array<{
        id: string;
        title: string;
    }>;
};

export const useListChairsCatalog = (data: ChairOverviewVariables, page: number, limit: number) => {
    const graphqlOptions = useGraphQLContext();
    return useQuery<ChairEntries<ChairOverview>>({
        queryKey: ["chairOverview", data, page, limit],
        queryFn: async () => {
            const offset = page * limit;
            const variables = { ...data, limit: limit, offset: offset };
            return request({
                ...graphqlOptions,
                document: listChairsCatalog,
                variables: variables,
            });
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
};

export const useGetChairVariantModel = (data: { id: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairModelUrlOut>({
        queryKey: ["modelUrl", data.id],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: getChairVariantUrl,
                variables: variables,
            });
        },
        keepPreviousData: true,
        enabled: data.id !== "",
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export const useGetChairInformation = (data: { id: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairEntries<ChairVariantInfo>>({
        queryKey: ["chairInfo", data.id],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: getChairVariantInfo,
                variables: variables,
            });
        },
        enabled: data.id !== "",
        keepPreviousData: true,
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export const useGetChairConfiguratorInfo = (data: { id: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairEntries<ChairConfiguratorInfo>>({
        queryKey: ["chairConfigInfo", data.id],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: getChairConfiguratorInfo,
                variables: variables,
            });
        },
        enabled: data.id !== "",
        keepPreviousData: true,
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export const useListChairVariants = (data: { id: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairEntries<ChairVariants>>({
        queryKey: ["chairVariants", data.id],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: listChairVariants,
                variables: variables,
            });
        },
        enabled: data.id !== "",
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export const useListVariantCovers = (data: { id: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairEntries<VariantCovers>>({
        queryKey: ["variantCovers", data.id],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: listVariantCovers,
                variables: variables,
            });
        },
        enabled: data.id !== "",
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export const useListVariantCategoryFinishing = (data: { id: string; category: string; search: string }) => {
    const graphqlOptions = useGraphQLContext();

    return useQuery<ChairEntries<VariantFinishing>>({
        queryKey: ["variantCovers", data.id, data.category, data.search],
        queryFn: async () => {
            const variables = { ...data };
            return request({
                ...graphqlOptions,
                document: listVariantCategoryFinishing,
                variables: variables,
            });
        },
        enabled: !!data.id && !!data.category,
        keepPreviousData: true,
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};
