export enum Step {
    CATALOG,
    VARIANT,
    COVER,
    FRAME,
    ACCESSORY,
    CART,
    OVERVIEW,
    THANK_YOU,
}
